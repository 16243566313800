import parser from 'json-templates'
import { SaseIAProviderProps, Subtenant } from '../types/redux-types'
import { isNil, isNilOrEmptyString } from '../utils/isNil'
import NetworkManager from './NetworkManager'
import { UserData } from '@panw/token-manager'
import HTTPReqResponseInterceptor, { PreProcessorCallback } from '../utils/HTTPReqResponseInterceptor'
import Logger, { LogLevel } from '../utils/Logger'
import { AnalyticsType, FQDNTypes, GLOBAL_SERVICE_FQDN_NAME, MainStateFnType, ErrorDataCode, MANDATORY_APPS } from '../constants/uiConstants'
import { ErrorItf } from '../types/types'
import { isFn } from '../utils/isFn'
import isEmpty from '../utils/isEmpty'
import transformTenant from "../utils/transformTenant";
import AdemManager from './AdemManager'
import { uuid } from 'short-uuid'
import { getMicroAppVars, getState } from "@sparky/framework";

/**
 * @param tenant - equivalent to TSG
 * @param subtenant - leave empty if no subtenant
 */
export const featureKeyFormatter = (currentTenant: string, currentSubTenant: string): string =>
    `cosmosFeatures-${currentTenant}${!isNil(currentSubTenant) ? `:${currentSubTenant}`: ""}`

class APIManager {
  private static instance: APIManager
  private csId: number
  private userEmail: string
  private tokenManager: any
  private networkManager = NetworkManager.getInstance()
  private ademManager = AdemManager.getInstance()
  private featureMap = new Map<string, unknown>()
  private appType: string
  private paTenantId: string
  private standalone = false
  private accountName: string
  private tenantId: string
  private userData: UserData
  private withAuth: boolean
  private locale = 'en'
  private getJWT: () => string
  private privateApp = false
  private apiResource: string
  private apiPath: string
  private coreapiPath: string;
  private coreapiResource: string;
  private tsgSupport: boolean
  private tsgId: string
  private tenantPath: string
  private isInit: boolean
  private mainStateFn: MainStateFnType

  // private constructor to enforce singleton-ness
  private constructor() {
    /** */
  }

  public static getInstance(): APIManager {
    if (isNil(this.instance)) {
      this.instance = new APIManager()
    }
    return this.instance
  }

  public getAPIResource() {
    return this.apiResource
  }

  public getAPIPath() {
    return this.apiPath
  }

  public getTenantPath() {
    return (tenant: string) => {
      return parser(this.tenantPath)({ tenant })
    }
  }

  public updateAPIServer(apiServer: string): void {
    NetworkManager.getInstance().updateServersFQDNs(apiServer, 'apiServer')
  }

  public getFQDN(fqdnName: FQDNTypes): string {
    return NetworkManager.getInstance().serverFQDNForName(fqdnName)
  }

  public init(props: SaseIAProviderProps): void {
    const { initialState, withAuth } = props
    if (this.isInit) {
      return
    }
    this.isInit = true
    if (initialState?.locale) {
      this.locale = initialState.locale
    }
    if (isFn(initialState?.getToken)) {
      this.getJWT = initialState.getToken!
    }
    if (isFn(initialState?.getMainState)) {
      this.mainStateFn = initialState.getMainState
    }
    this.tsgSupport = isFn(initialState?.hasTsgSupport) && initialState.hasTsgSupport!()
    this.privateApp = initialState.isPrivateSite!
    this.tsgId = initialState.tsgId!
    this.setAPIInfo()
    // buildConfig(this.locale);
    this.withAuth = withAuth || false
    const { tokenManager, appPortalUrl, accountInfo, appType, serverFqdns } = initialState ?? {}
    Logger.setLevel(LogLevel.INFO)

    this.ademManager.setAPIInfo({supportForTsgId: this.tsgSupport, tsgId: this.tsgId, getToken: this.getJWT, serverFqdns:initialState.demServerFqdns });

    NetworkManager.getInstance().setAPIInfo({
      apiResource: this.getAPIResource(),
      apiPath: this.getAPIPath(),
      supportForTsgId: this.hasTsgSupport(),
      isPrivateApp: this.isPrivateApp()
    })

    if (!isNil(serverFqdns)) {
      NetworkManager.getInstance().setServerFQDNs(initialState?.serverFqdns)
    }
    if (!isNil(props.authData)) {
      NetworkManager.getInstance().setAuthData(props.authData)
      this.ademManager.setAuthData(props.authData);
      this.userData = props.authData.userData
    }
    if (!isNil(props.initializePendo)) {
      this.featureMap.set('initializePendo', props.initializePendo)
    }
    if (!isNil(appPortalUrl)) {
      this.featureMap.set('appPortalUrl', initialState.appPortalUrl)
    }

    this.standalone = props.standalone ?? this.standalone

    const { accountId, tenant: paTenantId, accountName, lsTenantId } = accountInfo ?? {}
    if (!isNil(paTenantId)) {
      this.paTenantId = paTenantId
    }
    if (!isNil(tokenManager)) {
      this.tokenManager = tokenManager
    }
    if (!isNil(appType)) {
      this.appType = appType
    }
    if (!isNil(lsTenantId)) {
      this.tenantId = lsTenantId
    }
    if (isNil(tokenManager) || isNil(accountId) || isNil(appType)) {
      const tokenMgr = isNil(tokenManager) ? '' : ' token manager'
      const accountIdMsg = isNil(accountId) ? '' : ' CSP account id'
      const appType = isNil(accountId) ? '' : ' application type'
      const tenantId = isNil(paTenantId) ? '' : ' tenant ID'
      if (!this.standalone) {
        Logger.fatal(`Missing configuration for:${tokenMgr}${accountIdMsg}${appType}${tenantId}`)
      }
    } else {
      this.csId = accountId
      this.appType = appType
      this.accountName = accountName
      this.tenantId = lsTenantId
    }
  }

  public getTenantId(): string {
    return this.tenantId
  }


  public hasFeature(feature: string): boolean {
    return this.featureMap.has(feature)
  }

  public getFeature(feature: string, defaultValue: unknown = false): unknown {
    if (this.hasFeature(feature)) {
      return this.featureMap.get(feature)
    }
    return defaultValue
  }

  public fetchQueryData(props: {
    requestId: string
    queryBody?: string
    queryName: string
    method?: string
    subtenant?: string
    tenant: string
    fqdnName?: FQDNTypes
    preProcessor?: PreProcessorCallback
    service?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    const { requestId, queryBody, queryName, method, subtenant, tenant, fqdnName, preProcessor, service } = props
    HTTPReqResponseInterceptor.onStartRequest(AnalyticsType.Data, requestId)
    return (async () => {
      try {
        const token = this.getToken()
        this.networkManager
          .fetchQuery({
            queryBody,
            queryPath: queryName,
            method,
            subtenant,
            tenant,
            fqdnName,
            token,
            requestId,
            service,
          })
          .then((response) => {
            HTTPReqResponseInterceptor.onSuccess(response, AnalyticsType.Data, requestId, preProcessor)
            return Promise.resolve(response)
          })
          .catch((error) => {
            HTTPReqResponseInterceptor.onError(error, AnalyticsType.Data, requestId)
            return Promise.reject(error)
          })
      } catch (err: any) {
        const error: ErrorItf = {
          errorCode: 'TM_ERROR',
          details: err.message,
        }
        console.error(`Token manager get token failure: ${err.message}`)
        HTTPReqResponseInterceptor.onError(error, AnalyticsType.Data, requestId)
        return Promise.reject(error)
      }
    })()
  }

  public async fetchQuery(props: {
    queryBody?: string
    queryPath: string
    method?: string
    subtenant?: string
    tenant: string
    fqdnName?: FQDNTypes
    requestId?: string
    responseType?: string
    rawAxiosResponse?: boolean
    fqdn?: string
    user?: string
    service?: string
    // TODO: find a way to use a generic type for the response
    // as now it directly returns the response from the server
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    const {
      queryBody,
      queryPath,
      method,
      subtenant,
      tenant,
      fqdnName,
      requestId,
      responseType,
      rawAxiosResponse,
      fqdn,
      user,
      service,
    } = props
    const token = this.getToken()
    return this.networkManager.fetchQuery({
      queryBody,
      queryPath,
      method,
      subtenant,
      tenant,
      fqdnName,
      token,
      requestId,
      responseType,
      rawAxiosResponse,
      fqdn,
      user,
      service,
    })
  }

  public async fetchTenantFQDN(props: {
    tenantId: string
    api?: string
    fqdnName?: FQDNTypes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    const token = this.getToken()
    const fqdnName = props.fqdnName ?? GLOBAL_SERVICE_FQDN_NAME
    return this.networkManager.fetchTenantFQDN({ ...props, fqdnName, token })
  }

  public updateActiveDashboard(dashboadId: string, type: AnalyticsType): void {
    // DefinitionManager.updateActiveDashboard(dashboadId, type);
  }

  public cancelRequest(requestId: string, message?: string): void {
    this.networkManager.cancelRequest(requestId, message)
  }
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchTenantInfo(currentTenant: string): Promise<any> {
    const tenantPath = this.getTenantPath()(currentTenant)
    const API_RESOURCE = this.getAPIResource()
    const promisses = [
      this.fetchQuery({
        requestId: 'subtenant',
        queryPath: `${API_RESOURCE}/account/${tenantPath}subtenant`,
        method: 'GET',
        tenant: currentTenant,
      }),
    ]
    return Promise.all(promisses)
      .then((value) => Promise.resolve(value))
      .catch((error) => Promise.reject(error))
  }

  public fetchTenantSubtenantInfo(
    currentTenant: string,
    currentSubTenant: string
  ): //eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any> {
    const tenantPath = this.getTenantPath()(currentTenant)
    const API_RESOURCE = this.getAPIResource()
    return Promise.all([
      this.fetchQueryData({
        requestId: 'licenseUsage',
        queryName: `${API_RESOURCE}/license/${tenantPath}allocated`,
        method: 'GET',
        tenant: currentTenant,
        subtenant: currentSubTenant,
      }),
      this.fetchQueryData({
        requestId: 'feature',
        queryName: `${API_RESOURCE}/account/${tenantPath}feature`,
        method: 'GET',
        tenant: currentTenant,
        subtenant: currentSubTenant,
      }),
    ])
      .then((value) => Promise.resolve(value))
      .catch((error) => Promise.reject(error))
  }

  /**
   * This is used to first get all tenant features to show/hide main navigation
   * @param currentTenant
   * @param currentSubTenant
   */
  public fetchFeatures(
    currentTenant: string,
    currentSubTenant: string
  ): //eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any> {
    const tenantPath = this.getTenantPath()(currentTenant)
    const API_RESOURCE = this.getAPIResource()
    return this.fetchQuery({
      requestId: 'feature',
      queryPath: `${API_RESOURCE}/account/${tenantPath}feature`,
      method: 'GET',
      tenant: currentTenant,
      subtenant: currentSubTenant,
    })
  }

  /**
   * This is used to first get user features to show/hide main navigation
   * @param user
   */
  public fetchUserFeatures(user: string, fqdn: string):
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
      Promise<any> {
      const API_RESOURCE = this.getAPIResource();
      return this.fetchQuery({
          requestId: "userfeature",
          queryPath: `${API_RESOURCE}/account/feature/role`,
          method: "GET",
          tenant: "",
          user,
          fqdn
      })
  }

  /**
     * Initialize precaching
     */
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    public initPreCache(currentTenant: string, subTenant: string): Promise<any> {
      const API_RESOURCE = this.getAPIResource();
      return this.fetchQuery({
          requestId: "initPreCache",
          queryPath: `${API_RESOURCE}/precache/init`,
          method: "GET",
          tenant: currentTenant,
          subtenant: subTenant
      })
   }

  /**
   * Precaching heartbeat
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public precacheHeartbeat(currentTenant: string, subTenant: string): Promise<any> {
      const API_RESOURCE = this.getAPIResource();
      return this.fetchQuery({
          requestId: "precacheHeartbeat",
          queryPath: `${API_RESOURCE}/precache/heartbeat`,
          method: "GET",
          tenant: currentTenant,
          subtenant: subTenant
      })
  }

  /**
   * DO NOT TOUCH, ANY CHANGE HERE MUST BE ALSO CHANGED IN SASE-IA-UI REPO
   * getTenantWithSubtenantsForAllRegions FUNCTION CALL
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getTenantWithSubtenantsForAllRegions(): Promise<any> {
    const API_RESOURCE = this.getAPIResource()
    return this.fetchQuery({
      queryPath: `${API_RESOURCE}/account/tenant/regions`,
      method: 'GET',
      tenant: '',
      fqdnName: GLOBAL_SERVICE_FQDN_NAME,
    })
      .then((response) => {
        const tenants = response?.data?.map((region: { fqdn: any }) => {
          return this.fetchQuery({
            queryPath: `${API_RESOURCE}/account/tenant?sub_tenant=true`,
            method: 'GET',
            tenant: '',
            fqdn: region.fqdn,
            rawAxiosResponse: true,
          })
        })
        return Promise.allSettled(tenants)
      })
      .then((response) => {
        const tenantMap: any = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        response.filter(promise => promise.status === 
          "fulfilled").map(({ value: item }) => {
          const { config, data } = item
          const url = new URL(config?.url)
          const fqdn = `${url.protocol}//${url.host}`
          data?.['data'].forEach((item: any) => {
            const { name, gcsTenantId, cdlTenantId, platformType, cyrEnv } = transformTenant({
              tenantRow: item,
              hasTsgSupport: this.hasTsgSupport(),
            })
            if (isNilOrEmptyString(name)) {
              return
            }
            const [tsgId, subtenantId] = gcsTenantId?.split(':')
            if (isNilOrEmptyString(tsgId) || isNilOrEmptyString(subtenantId) || tsgId === "0" || subtenantId === "0") {
              return
            }
            // store in a common map, if it is a new tsg entry or overriding an exiting entry with hybrid tsg
            if (isNil(tenantMap[tsgId]) || platformType === "hybrid") {
              tenantMap[tsgId] = {
                value: cdlTenantId,
                /** AIOPS-11604 - If platform type is hybrid, then
                 * Check if the stored tsgId has any subtenant options - then use the existing subtenants
                 * Else, use empty array as existing before
                 */
                options: platformType === "hybrid" ? (tenantMap[tsgId]?.options ?? []) : [],
                fqdn: fqdn,
                platformType,
                gcsTenantId,
                cyrEnv
              }

              // default subtenant is same as parent cdl tenant id
              if (cdlTenantId === subtenantId) {
                tenantMap[tsgId].label = name
                tenantMap[tsgId].value = tsgId
                tenantMap[tsgId].gcsTenantId = gcsTenantId
              }  
            }
            /** AIOPS-11604 - Check if subtenantId is unique in the parent tsgId options
             * If isNil - then push unique subtenant
             * Else - do not push as it is a duplicate subtenant
            */
            if(isNil(tenantMap?.[tsgId]?.options?.find((o: any) => o?.value === subtenantId))){
              // push all other subtenants as children is the platform type is not ngfw
              // will only push if platform type is prisma_access or hybrid
              if(platformType !== "ngfw") {
                tenantMap[tsgId]?.options?.push({
                  value: subtenantId,
                  label: name,
                  gcsTenantId,
                  cyrEnv
                });
              }
            }
          })
        });

        /* In the GCS set the default tenant from the configuration */
        const featureFlags = getMicroAppVars("pai")?.featureFlags;
        const gcsDefaultTenantId = !isNil(featureFlags) ? JSON.parse(featureFlags)?.["gcs_default_tsg_id"] : 0;
        const gcsDefaultTenant = tenantMap[gcsDefaultTenantId];

        /*
          Remove from the map if the tenant is present and return it as the first tenant in the list
        */
        if (!isNil(gcsDefaultTenant)) {
          delete tenantMap[gcsDefaultTenantId];

          return [ gcsDefaultTenant, ...Object.values(tenantMap) ];
        }

        return Object.values(tenantMap);
      })
  }

  public hasTsgSupport() {
    return this.tsgSupport === true
  }

  public isPrivateApp() {
    return this.privateApp === true
  }

  public getAccountId() {
    return this.csId
  }

  public getAppType() {
    return this.appType
  }

  public getPATenantId() {
    return this.paTenantId
  }

  public getToken(): string {
    if (isFn(this.getJWT)) {
      const token = this.getJWT()
      Logger.trace(`JWT token: ${token}`)
      return token
    }
    return "";
  }

  public getMainState() {
    /** AIOPS-8509: Changed to getState() as per Sparky Main State Depreciation Jan '24
     * This updates all references of getMainState() to fetch from top level state instead
     */
    return getState() ?? {};
  }

  private getTokenMappings(): Map<string, Set<string>> {
    return new Map([[this.appType, new Set([this.paTenantId])]])
  }

  private setAPIInfo() {
    const conditionTSGOrPrivateApp = this.hasTsgSupport() || this.isPrivateApp();
    const dataSvcAPIVersion = "v3.0";
    const DATA_SVC_API_RESOURCE = `/api/sase/${dataSvcAPIVersion}`;
    const TENANT_PATH = this.isPrivateApp() || this.hasTsgSupport() ? "" : "tenant/{{tenant}}/";
    const DATA_SVC_API_PATH = conditionTSGOrPrivateApp ? `${DATA_SVC_API_RESOURCE}/resource`
        : `${DATA_SVC_API_RESOURCE}/resource/tenant/{{tenant:99999}}`;
    this.apiResource = DATA_SVC_API_RESOURCE;
    this.apiPath = DATA_SVC_API_PATH;
    this.tenantPath = TENANT_PATH;
}

  public async fetchAdemTenantInfo(): Promise<any> {
    const tenantInfo = this.ademManager.fetchTenantInfo();

    return tenantInfo
      .then((value) => Promise.resolve(value))
      .catch((error) => Promise.reject(error))
  }

	public async performSearch(
		searchTerm: string,
		currentTenant: string,
		currentSubTenant: string
	): Promise<any> {
		const API_PATH = this.getAPIPath()

		const searchPayload = {
			searchTerm: searchTerm,
			filter: {
				rules: [
					{
						property: 'event_time',
						operator: 'last_n_days',
						values: [30]
					}
				]
			},
			count: 100
		}

		return this.fetchQuery({
			requestId: `global_search_${uuid()}`,
			queryPath: `${API_PATH}/query/search/global_search`,
			method: 'POST',
			tenant: currentTenant,
			subtenant: currentSubTenant,
			queryBody: JSON.stringify(searchPayload)
		})
			.then(value => Promise.resolve(value))
			.catch(error => Promise.reject(error))
	}
}

const APIManagerInstance = APIManager.getInstance()

export { APIManagerInstance };
