/* Warning: please do not statically import any dependencies here except for static assets like icons */
import {
    PolicyIcon,
    SitemapIcon
} from '@panwds/icons';
import pkg from "../package.json";
import { extractFeatures} from "./utils/extractFeatures";
import {
    APIManagerInstance,
    featureKeyFormatter,
    setMicroAppLicenseInfo,
    setMicroAppTenantInfo,
    MICRO_APP_ADEM,
    isNil
} from '@saseia/ui';
import { buildProps } from './utils/buildProps';
import { getMFEPropertyAdemLicense } from './utils/mfeProperty';
import { getMicroAppVars } from "@sparky/framework";

const INSTANCE_STATUS_RUNNING = 'running';

/**
 * 
 * SASE IA Micro App Covers the following use cases
 * Use Case 1: Provide a stitched view for tab level integration with routes, TabListContainer
 * Use Case 2: Provide a Tenant vs Sub Tenant Selector for GCS vs Customer App
 * Use Case 3: Provide tenancy information in case of stitched or PAI exposed components
 * 
 */
export default async ({ sparkyFramework }) => {
    console.info(`%c${pkg.name}: ${pkg.version}`, "font-size: 14px; color: white; background-color: black; border-radius: 8px; padding: 10px 15px;");
    
    return async ({ auth, main }) => {
        sparkyFramework.actions.setValues({saseIaGlobalState:{}});

        // Check for GCS vs Customer App
        const frameworkVars = sparkyFramework.getFrameworkVars();
        const { private_site_origin } = frameworkVars;
        const isPrivateApp = Boolean(private_site_origin);
        const featureFlags = getMicroAppVars('pai')?.featureFlags;
        const gcsTsgEnabled = !isNil(featureFlags) ? JSON.parse(featureFlags).gcs_tsg_enabled : false;

        // Set license for micro apps
        const sdwanInstance = auth?.instances?.get('cgx');
        const licenseInfo = {
            isSDWANInstRunning: isPrivateApp ? false : (sdwanInstance?.status === INSTANCE_STATUS_RUNNING),
            isPAInstRunning: isPrivateApp ? true : (auth?.instances?.isFawkesRunning),
        };

        // The following function will take care of three things
        // 1. Instantiate the initial values required in the API/Network Manager Singletons
        // 2. Set initial values for saseIaGlobalState
        // 3. Set the tenancy information for both GCS vs Customer App
        const { fetchAndSetTenantInfo } = await import("./fetchAndSetTenantInfo");
        const { providerProps } = buildProps({ main, auth});

        fetchAndSetTenantInfo({ sparkyFramework, auth, isPrivateApp, providerProps });

        // Initialize dashboards and fetch micro app license info
        if (!isPrivateApp) {
            const { fetchMicroAppLicense } = await import('./fetchMicroAppLicense');
            let ademLicense = getMFEPropertyAdemLicense(auth)
            if(!ademLicense) {
                ademLicense = await fetchMicroAppLicense();
            }

            setMicroAppLicenseInfo(MICRO_APP_ADEM, {
                licenseMu: ademLicense.licenseMu,
                licenseRn: ademLicense.licenseRn,
            });
            setMicroAppTenantInfo(MICRO_APP_ADEM, ademLicense);
        }

        const isADEMTier1Role = auth?.access?.hasRole("adem_tier_1_support");

        const generateChildRoutesWithProps = (routes) => (
            routes.map((route) => ({
                ...route,
                mapStateToProps: (props) => ({...props,
                    licenseInfo,
                    isPrivateApp
                }),
            }))
        );
        
        const hasApplications = () => {
            const { saseIAMainState } = sparkyFramework.getState();
            const cosmosFeatures = saseIAMainState?.cosmosFeatures;
             /**
             * we write cosmosFeatures to state in either pai, unified app, or DEM deduped calls. It depends * on which microapp sparky calls first.  This will detect an issue between app code.
             */
             if (!cosmosFeatures) {
                console.error("cosmosFeatures is not defined. Left hand nav will not work as designed.")
            }
            const sparkyState = sparkyFramework.getState();
            const SDWANRBAC = sparkyState?.sdwan_rbac?.monitor?.applications;
            const frameworkVars = sparkyFramework.getFrameworkVars();
            const { private_site_origin } = frameworkVars;
            const isPrivateApp = Boolean(private_site_origin);
            return isPrivateApp && (SDWANRBAC || cosmosFeatures?.findIndex(feature => feature.feature === "panw_sase:prisma_access_table") >= 0)
        }

        const hasBranchSites = () => {
            const { saseIAMainState } = sparkyFramework.getState();
            const cosmosFeatures = saseIAMainState?.cosmosFeatures;
            /**
             * we write cosmosFeatures to state in either pai, unified app, or DEM deduped calls. It depends * on which microapp sparky calls first.  This will detect an issue between app code.
             */
            if (!cosmosFeatures) {
                console.error("cosmosFeatures is not defined. Left hand nav will not work as designed.")
            }
            const sparkyState = sparkyFramework.getState();
            const SDWANRBAC = sparkyState?.sdwan_rbac?.monitor?.branchSites;
            return SDWANRBAC || cosmosFeatures?.findIndex(feature => feature.feature === "panw_sase:branch_sites") >= 0
        }
        
        const hasDataCenter = () => {
            const { saseIAMainState } = sparkyFramework.getState();
            const cosmosFeatures = saseIAMainState?.cosmosFeatures;
            /**
             * we write cosmosFeatures to state in either pai, unified app, or DEM deduped calls. It depends * on which microapp sparky calls first.  This will detect an issue between app code.
             */
             if (!cosmosFeatures) {
                console.error("cosmosFeatures is not defined. Left hand nav will not work as designed.")
            }
            const sparkyState = sparkyFramework.getState();
            const SDWANRBAC = sparkyState?.sdwan_rbac?.monitor?.dataCenters;
            return SDWANRBAC || !isADEMTier1Role && cosmosFeatures?.findIndex(feature => feature.feature === "panw_sase:data_center") >= 0
        }

        const hasSubscriptionUsage = () => {
            const { saseIAMainState } = sparkyFramework.getState();
            const cosmosFeatures = saseIAMainState?.cosmosFeatures;
            return licenseInfo.isSDWANInstRunning || cosmosFeatures?.findIndex(feature => feature.feature === "panw_sase:subscription_usage") >= 0
        }

        const monitorRoutes = [
            {
                key: 'monitor-applications',
                title: 'Applications',
                contentClassName: 'panwds-tw3 monitor-applications',
                path: '/monitor/applications',
                // keep this false, else the app details routes dont work for SDWAN and other micro apps
                exact: false,
                component: () => import('./SASEIAUnifiedRoutes'),
                avail: hasApplications,
            },
            {
                key: 'monitor-branch-sites',
                title: 'Branch Sites',
                contentClassName: 'panwds-tw3 monitor-branch-sites',
                path: '/monitor/branch-sites',
                component: () => import('./SASEIAUnifiedRoutes'),
                avail: hasBranchSites
            },
            {
                key: 'monitor-data-centers',
                title: 'Data Centers',
                contentClassName: 'panwds-tw3 monitor-data-centers',
                path: '/monitor/data-centers',
                component: () => import('./SASEIAUnifiedRoutes'),
                avail: hasDataCenter
            },
            {
                key: 'monitor-subscription-usage',
                title: 'Subscription Usage',
                contentClassName: 'panwds-tw3 monitor-subscription-usage',
                path: '/monitor/subscription-usage/subscriptionUsage',
                component: () => import('./SASEIAUnifiedRoutes'),
                avail: hasSubscriptionUsage
            }
        ];

        const { saseIAMainState } = sparkyFramework.getState();
        const saseIAFeatures = extractFeatures(auth);

        if (saseIAMainState?.cosmosFeatures?.length > 0) {
            /* Do nothing as `cosmosFeatures` is already set */
        } else if (saseIAFeatures?.length > 0) {
            /*
                If cosmosFeatures is not set but Sparky MFE is set, then set the cosmosFeatures and return
                If saseIAFeatures has missing MFE feature data, fallback to the feature API
            */
            sparkyFramework.actions.setValues({ saseIAMainState: { cosmosFeatures: saseIAFeatures }});
        } else {
            // Make the API call to get the features
            // await write to sparky state before sending navigation (left hand nav)
            await sparkyFramework.dedupPromise("navigationSync", () => new Promise(async (resolve, reject) => {
                try {
                    const tsgId = auth?.tsg_id;
                    const { email } = auth?.user ?? {};
                    const { initialState } = providerProps;
                    const { serverFqdns } = initialState;
                    const { gc_fqdn } = serverFqdns ?? {};

                    const promise = gcsTsgEnabled ?
                        sparkyFramework.dedupPromise(featureKeyFormatter(tsgId), () =>
                            APIManagerInstance.fetchFeatures(tsgId, tsgId), { keepPromiseIn: -1}) :
                        (isPrivateApp ? sparkyFramework.dedupPromise(`cosmosRole-gcsInit`, () =>
                                APIManagerInstance.fetchUserFeatures(email, gc_fqdn), { keepPromiseIn: 60000}) :
                            sparkyFramework.dedupPromise(featureKeyFormatter(tsgId), () =>
                                APIManagerInstance.fetchFeatures(tsgId, tsgId), { keepPromiseIn: -1})
                        )
                    ;
                    const result = await promise;

                    sparkyFramework.actions.setValues({ saseIAMainState: { cosmosFeatures: result?.data }});
                    resolve();
                } catch(e) {
                    reject(e);
                }
            }), { keepPromiseIn: 60000 });
        }
    
        
        // Change navigation array for workflows - showWorkflowsOnboardingPage 
        // then single main menu, else workflows menu with children for SDWAN
        // Per Wilson top level avail is not needed here, and placeholders can be removed, plus manage can be removed
        return {
            navigation: [
                {
                    key: 'monitor',
                    title: 'Monitor',
                    icon: SitemapIcon,
                    contentClassName: 'monitor',
                    path: '/monitor',
                    mergeNav: true,
                    children: generateChildRoutesWithProps(monitorRoutes),
                },
                {
                    key: 'manage',
                    title: 'Manage',
                    icon: PolicyIcon,
                    path: '/manage',
                    mergeNav: true,
                },
            ],
            exposes: {
                default: {
                    TenantFetcher: () => import('./TenantFetcherWrapper'),
                    TenantSelector: () => import('./TenantSelectorWrapper'),
                    SubTenantSelector: () => import('./SubTenantSelectorWrapper'),
                    GlobalSearch: () => import('./GlobalSearchWrapper')
                },
            },
        };
    };
};      